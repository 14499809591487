import React, { useState, useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { isEqual } from 'lodash';
import Checkbox from 'components/ui-components-v2/Checkbox';
import { UNASSIGNED_KEY } from 'components/ui-base/GenericFilter/constants';
import GenericFilterShowMore from './show-more';
import GenericFilterDelete from './delete';
import '../styles/type-select-multiple-subs.scss';

/**
 * Sort the options with selected options first if this filter has a show more option
 * @param {array} options to sort.
 * @param {array} value holds the selected options
 * @param {boolean} hasShowmore
 * @returns
 */
const getSortedOptions = (options, value, hasShowmore) => {
    const doSort = hasShowmore && Array.isArray(value) && value.length > 0;
    if (doSort) {
        const newOptions = [...options];
        newOptions.sort((a, b) => {
            const aSelected = value.includes(a.key);
            const bSelected = value.includes(b.key);

            if (aSelected && !bSelected) {
                return -1;
            } else if (bSelected && !aSelected) {
                return 1;
            } else {
                return 0;
            }
        });
        return newOptions;
    } else {
        return options;
    }
};

/**
 * Build an object with a representation of the selected sub items per root category.
 * @param {array} value
 * @returns
 */
const getSelectedState = (value) => {
    const result = {};
    if (Array.isArray(value) && value.length > 0) {
        [...value].forEach((v) => {
            const vArray = v.split('.');
            if (vArray.length === 2) {
                const root = vArray[0];
                if (result[root]) {
                    result[root] = result[root] + 1;
                } else {
                    result[root] = 1;
                }
            } else if (v === UNASSIGNED_KEY) {
                result[UNASSIGNED_KEY] = 1;
            }
        });
    }
    return result;
};

// Determine if the root category is checked
const rootChecked = (option, selectedState) => {
    if (option.key === UNASSIGNED_KEY) {
        if (selectedState[UNASSIGNED_KEY] === 1) return true;
    }
    return Array.isArray(option.subOptions) && option.subOptions.length === selectedState[option.key];
};

const rootIndeterminate = (option, selectedState) => {
    if (option.key === UNASSIGNED_KEY) {
        return false;
    }
    return Array.isArray(option.subOptions) && selectedState[option.key] > 0 && option.subOptions.length > selectedState[option.key];
};

/**
 * Display a filter of type select in the vertical list with multiple options selectable
 * @param {*} param0
 * @returns
 */
const GenericFilterTypeSelectMultipleSubs = ({ filter, value = [], maxOptionsShown, onChange }) => {
    const hasShowmore = useRef(filter.options && filter.options.length > maxOptionsShown);
    const [options, setOptions] = useState(filter.options);
    const [sortedOptions, setSortedOptions] = useState(getSortedOptions(filter.options, value, hasShowmore.current));
    const [activeOptions, setActiveOptions] = useState('sorted');
    const [showOptions, setShowOptions] = useState(maxOptionsShown);

    /**
     * Handle a change in selected root categories
     * @param {event} e
     * @param {boolean} checked
     * @param {boolean} indeterminate
     */
    const handleRootChange = (e, checked, indeterminate) => {
        const newValue = [...value];
        const thisOption = options.find((o) => o.key === e.target.value);
        if (thisOption && thisOption.subOptions) {
            thisOption.subOptions.forEach((so) => {
                if (!checked || indeterminate) {
                    if (!newValue.includes(so.key)) {
                        newValue.push(so.key);
                    }
                } else {
                    if (newValue.includes(so.key)) {
                        newValue.splice(newValue.indexOf(so.key), 1);
                    }
                }
            });
        } else if (thisOption.key === UNASSIGNED_KEY) {
            if (newValue.includes(e.target.value)) {
                newValue.splice(value.indexOf(e.target.value), 1);
            } else {
                newValue.push(e.target.value);
            }
        }
        onChange(filter.name, newValue);
    };

    /**
     * Handle changed filter
     * @param {event} e
     */
    const handleChange = (e) => {
        const newValue = [...value];
        if (newValue.includes(e.target.value)) {
            newValue.splice(value.indexOf(e.target.value), 1);
        } else {
            newValue.push(e.target.value);
        }
        onChange(filter.name, newValue);
    };

    /**
     * Toggle the show more option
     * @param {boolean} show
     */
    const toggleShowMore = (show) => {
        if (show) {
            setShowOptions(options.length);
            setActiveOptions('original');
        } else {
            setShowOptions(maxOptionsShown);
            setActiveOptions('sorted');
        }
    };

    // Update the stored option lists when their content changes.
    useEffect(() => {
        if (!isEqual(options, filter.options)) {
            setOptions(filter.options);
            setSortedOptions(getSortedOptions(filter.options, value, hasShowmore.current));
        }
    }, [filter]);

    useEffect(() => {
        if (showOptions !== maxOptionsShown) {
            setSortedOptions(getSortedOptions(filter.options, value, hasShowmore.current));
        }
    }, [value]);

    // Determine which option list to use in JSX.
    const displayOptions = activeOptions === 'sorted' ? sortedOptions : options;

    // Calculate the selected state on every render.
    const selectedState = getSelectedState(value);

    return (
        <div>
            <FormControl component="fieldset" className="generic-filter__type-select-multiple-subs">
                <FormGroup>
                    {displayOptions.map((option, i) => {
                        if (i >= showOptions) return null;
                        if (option.key !== UNASSIGNED_KEY && (!option.subOptions || !option.subOptions.length)) return null;
                        const checked = rootChecked(option, selectedState);
                        const indeterminate = rootIndeterminate(option, selectedState);
                        return (
                            <React.Fragment key={option.key}>
                                <div className="generic-filter__type-select-multiple-subs__option">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                indeterminate={indeterminate}
                                                color="default"
                                                onChange={(e) => handleRootChange(e, checked, indeterminate)}
                                                value={option.key}
                                                data-cy="genericFilter-option-checkbox"
                                            />
                                        }
                                        label={option.value || 'None'}
                                        classes={{ root: '', label: '' }}
                                    />
                                    {'count' in option && <div className="generic-filter__type-select-multiple-subs__count">{option.count}</div>}
                                </div>
                                {Array.isArray(option.subOptions) && option.subOptions.length && (checked || indeterminate) && (
                                    <div className="generic-filter__type-select-multiple-subs__option__sub">
                                        {option.subOptions.map((subOption) => (
                                            <div className="generic-filter__type-select-multiple-subs__option" key={subOption.key}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            size="small"
                                                            checked={value && value.includes(subOption.key)}
                                                            color="default"
                                                            onChange={(e) => handleChange(e)}
                                                            value={subOption.key}
                                                        />
                                                    }
                                                    label={subOption.value || 'None'}
                                                    classes={{ root: '', label: '' }}
                                                />
                                                {'count' in subOption && (
                                                    <div className="generic-filter__type-select-multiple-subs__count">{subOption.count}</div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </React.Fragment>
                        );
                    })}
                </FormGroup>
            </FormControl>
            {hasShowmore.current && <GenericFilterShowMore showMore={showOptions !== maxOptionsShown} onToggleShowMore={toggleShowMore} />}
            <GenericFilterDelete onDelete={() => onChange(filter.name, [])} active={value.length > 0} />
        </div>
    );
};

export default GenericFilterTypeSelectMultipleSubs;
