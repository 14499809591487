import React from 'react';
import classNames from 'classnames';
import Checkbox from 'components/ui-components-v2/Checkbox';
import ListItem from 'components/ui-components-v2/ListItem';
import TextField from 'components/ui-components-v2/TextField';
import Autocomplete from 'components/ui-components-v2/Autocomplete';
import Translation from 'components/data/Translation';
import Chip from 'components/ui-components-v2/Chip';
import Avatar from 'components/ui-components-v2/Avatar';
import GenericFilterDelete from './delete';
import FilterItemDetails from './filter-item-details';

import '../styles/type-autocomplete-multiple.scss';

/**
 * Display a filter of type autocomplete in the vertical list with multiple options selectable
 * @param {*} param0
 * @returns
 */
const GenericFilterTypeAutocompleteMultiple = ({ filter, value = [], className, onChange }) => {
    /**
     * Display the selected values as chip tags.
     */
    const renderTags = (values, getTagProps) => {
        return values.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });

            const renderAvatar = () => {
                return (
                    <>
                        {option.imageSrc ? (
                            <Avatar size="small" option src={option.imageSrc} className="generic-filter__type-autocomplete-multiple__tag__avatar">
                                {option.imageSrc}
                            </Avatar>
                        ) : null}
                    </>
                );
            };

            return <Chip size="small" label={option.value} key={key} {...tagProps} avatar={renderAvatar()} />;
        });
    };

    const onChangeFilter = (e, value) => {
        const thisValue = value.map((v) => v.key);
        onChange(filter.name, thisValue);
    };

    return (
        <div className={classNames('generic-filter__type-autocomplete-multiple', className)}>
            <Autocomplete
                id={'horizontal-autocomplete-multiple-' + filter.name}
                options={filter.options}
                onChange={(event, newValue) => {
                    onChangeFilter(event, newValue);
                }}
                classes={{
                    root: 'generic-filter__type-autocomplete-multiple__root',
                    inputRoot: 'generic-filter__type-autocomplete-multiple__input-root',
                    input: 'generic-filter__type-autocomplete-multiple__input',
                    paper: 'generic-filter__type-autocomplete-multiple__paper',
                    option: 'generic-filter__type-autocomplete-multiple__option'
                }}
                fullWidth
                multiple
                openOnFocus
                disableClearable
                disableCloseOnSelect
                disablePortal
                value={value.map((v) => filter.options.find((o) => o.key === v))}
                getOptionLabel={(option) => option.value}
                renderOption={(props, option, { selected }) => (
                    <ListItem {...props}>
                        <span>
                            <Checkbox size="small" color="default" checked={selected} data-cy="genericFilter-option-checkbox" />
                            <FilterItemDetails
                                imageSrc={option.imageSrc}
                                classes={{ text: option.colors ? '' : 'generic-filter__type-autocomplete-multiple__option__text' }}
                                text={option.value || Translation.get('labels.none', 'common')}
                                textStyle={option.colors ? { ...option.colors } : {}}
                            />
                        </span>

                        {'count' in option && <span className="generic-filter__type-autocomplete-multiple__count">{option.count}</span>}
                    </ListItem>
                )}
                renderTags={(values, getTagProps) => renderTags(values, getTagProps)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={Translation.get('filters.typeSelect', 'ui-base')}
                        variant="outlined"
                        slotProps={{
                            htmlInput: { ...params.inputProps }
                        }}
                    />
                )}
            />
            <GenericFilterDelete onDelete={() => onChange(filter.name, [])} active={value.length > 0} />
        </div>
    );
};

export default GenericFilterTypeAutocompleteMultiple;
