import React, { useState } from 'react';
import classNames from 'classnames';
import Popper from '@mui/material/Popper';
import ListItem from 'components/ui-components-v2/ListItem';
import Checkbox from 'components/ui-components-v2/Checkbox';
import TextField from 'components/ui-components-v2/TextField';
import Autocomplete from 'components/ui-components-v2/Autocomplete';

import '../styles/type-horizontal-autocomplete-multiple.scss';

const MuiPopper = function (props) {
    return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-end" />;
};

/**
 * Display a filter of type autocomplete in the horizontal bar with multiple options selectable
 * @param {*} param0
 * @returns
 */
const GenericFilterTypeHorizontalAutocompleteMultiple = ({ filter, value = [], className, onChange }) => {
    const [active, setActive] = useState(false);

    const renderTags = (selected) => {
        let result = '';

        try {
            selected.map((option) => (result = result + ', ' + option.value));
            result = result.substring(2);
        } catch (e) {}

        if (result.length > 20) {
            result = result.substring(0, 20) + '..';
        }

        return active ? null : <span className="generic-filter__type-horizontal-autocomplete-multiple__value">{result}</span>;
    };

    const onChangeFilter = (e, value) => {
        const thisValue = value.map((v) => v.key);
        onChange(filter.name, thisValue);
    };

    return (
        <div className={classNames('generic-filter__type-horizontal-autocomplete-multiple', className)}>
            <Autocomplete
                id={'horizontal-autocomplete-multiple-' + filter.name}
                options={filter.options}
                onChange={(event, newValue) => {
                    onChangeFilter(event, newValue);
                }}
                classes={{
                    root: 'generic-filter__type-horizontal-autocomplete-multiple__root',
                    inputRoot: 'generic-filter__type-horizontal-autocomplete-multiple__input-root',
                    input: 'generic-filter__type-horizontal-autocomplete-multiple__input',
                    paper: 'generic-filter__type-horizontal-autocomplete-multiple__paper',
                    option: 'generic-filter__type-horizontal-autocomplete-multiple__option'
                }}
                onFocus={() => setActive(true)}
                onBlur={() => setActive(false)}
                slots={{
                    popper: MuiPopper
                }}
                fullWidth
                multiple
                openOnFocus
                disableClearable
                disableCloseOnSelect
                value={value.map((v) => filter.options.find((o) => o.key == v))}
                getOptionLabel={(option) => option.value}
                renderOption={(props, option, { selected }) => (
                    <ListItem {...props}>
                        <Checkbox size="small" checked={selected} data-cy="genericFilter-option-checkbox" />
                        {option.value}
                    </ListItem>
                )}
                renderTags={(values) => renderTags(values)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={filter.label}
                        variant="outlined"
                        slotProps={{
                            htmlInput: { ...params.inputProps }
                        }}
                    />
                )}
            />
        </div>
    );
};

export default GenericFilterTypeHorizontalAutocompleteMultiple;
