import React, { useState, useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { isEqual } from 'lodash';
import Checkbox from 'components/ui-components-v2/Checkbox';
import Translation from 'components/data/Translation';
import GenericFilterShowMore from './show-more';
import GenericFilterDelete from './delete';
import FilterItemDetails from './filter-item-details';

import '../styles/type-select-multiple.scss';

/**
 * Sort the options with selected options first if this filter has a show more option
 * @param {array} options to sort.
 * @param {array} value holds the selected options
 * @param {boolean} hasShowmore
 * @returns
 */
const getSortedOptions = (options, value, hasShowmore) => {
    const doSort = hasShowmore && Array.isArray(value) && value.length > 0;
    if (doSort) {
        const newOptions = [...options];
        newOptions.sort((a, b) => {
            const aSelected = value.includes(a.key);
            const bSelected = value.includes(b.key);

            if (aSelected && !bSelected) {
                return -1;
            } else if (bSelected && !aSelected) {
                return 1;
            } else {
                return 0;
            }
        });
        return newOptions;
    } else {
        return options;
    }
};

/**
 * Display a filter of type select in the vertical list with multiple options selectable
 * @param {*} param0
 * @returns
 */
const GenericFilterTypeSelectMultiple = ({ filter, value = [], maxOptionsShown, onChange }) => {
    const hasShowmore = useRef(filter.options && filter.options.length > maxOptionsShown);
    const [options, setOptions] = useState(filter.options);
    const [sortedOptions, setSortedOptions] = useState(getSortedOptions(filter.options, value, hasShowmore.current));
    const [activeOptions, setActiveOptions] = useState('sorted');
    const [showOptions, setShowOptions] = useState(maxOptionsShown);

    /**
     * Handle changed filter
     * @param {event} e
     */
    const handleChange = (e) => {
        const newValue = [...value];
        if (newValue.includes(e.target.value)) {
            newValue.splice(value.indexOf(e.target.value), 1);
        } else {
            newValue.push(e.target.value);
        }
        onChange(filter.name, newValue);
    };

    /**
     * Toggle the show more option
     * @param {boolean} show
     */
    const toggleShowMore = (show) => {
        if (show) {
            setShowOptions(options.length);
            setActiveOptions('original');
        } else {
            setShowOptions(maxOptionsShown);
            setActiveOptions('sorted');
        }
    };

    // Update the stored option lists when their content changes.
    useEffect(() => {
        if (!isEqual(options, filter.options)) {
            setOptions(filter.options);
            setSortedOptions(getSortedOptions(filter.options, value, hasShowmore.current));
        }
    }, [filter]);

    useEffect(() => {
        if (showOptions !== maxOptionsShown) {
            setSortedOptions(getSortedOptions(filter.options, value, hasShowmore.current));
        }
    }, [value]);

    // Determine which option list to use in JSX.
    const displayOptions = activeOptions === 'sorted' ? sortedOptions : options;

    return (
        <div>
            <FormControl component="fieldset" className="generic-filter__type-select-multiple">
                <FormGroup>
                    {displayOptions.map((option, i) => {
                        if (i >= showOptions) return null;
                        return (
                            <div key={option.key} className="generic-filter__type-select-multiple__option">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={value && value.includes(option.key)}
                                            color="default"
                                            onChange={(e) => handleChange(e)}
                                            value={option.key}
                                            size="small"
                                            data-cy="genericFilter-option-checkbox"
                                        />
                                    }
                                    label={
                                        <FilterItemDetails
                                            imageSrc={option.imageSrc}
                                            classes={{ text: option.colors ? 'generic-filter__type-select-multiple__option__text' : '' }}
                                            text={option.value || Translation.get('labels.none', 'common')}
                                            textStyle={option.colors ? { ...option.colors } : {}}
                                        />
                                    }
                                    classes={{ root: '', label: option.colors ? 'generic-filter__type-select-multiple__color' : '' }}
                                />
                                {'count' in option && <div className="generic-filter__type-select-multiple__count">{option.count}</div>}
                            </div>
                        );
                    })}
                </FormGroup>
            </FormControl>
            {hasShowmore.current && <GenericFilterShowMore showMore={showOptions !== maxOptionsShown} onToggleShowMore={toggleShowMore} />}
            <GenericFilterDelete onDelete={() => onChange(filter.name, [])} active={value.length > 0} />
        </div>
    );
};

export default GenericFilterTypeSelectMultiple;
