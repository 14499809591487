import React, { useEffect } from 'react';
import { AlertProps } from '@mui/material';
import useRemoveBodyScroll from 'hooks/useRemoveBodyScroll';
import { DarkProvider } from 'components/ui-components/Dark';
import Alert from 'components/ui-components-v2/Alert';
import AlertTitle from 'components/ui-components-v2/AlertTitle';
import FullScreenOverlayToolbar from './toolbar';
import '../styles/main.scss';

interface Props {
    title: string;
    description?: string;
    onClose?: () => void;
    leftButtons?: React.ReactNode;
    centerButtons?: React.ReactNode | React.ReactNode[];
    rightButtons?: React.ReactNode;
    tourData?: string;
    dark?: boolean;
    children: React.ReactNode | React.ReactNode[];
    alertMessage?: {
        severity: AlertProps['severity'];
        title: string;
        description: string;
    };
}

const FullScreenOverlay = ({
    title = '',
    description,
    onClose,
    leftButtons = null,
    centerButtons = null,
    rightButtons = null,
    tourData,
    children,
    dark = false,
    alertMessage
}: Props) => {
    // Call hook to lock body scroll
    useRemoveBodyScroll();

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    /**
     * Close the overlay on escape key press
     * @param event - KeyboardEvent
     */
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key !== 'Escape') return;
        onClose?.();
    };

    return (
        <DarkProvider value={Boolean(dark)}>
            <div className="full-screen-overlay" data-mui-color-scheme={dark ? 'dark' : 'light'}>
                {alertMessage && (
                    <Alert className="full-screen-overlay__alert" severity={alertMessage.severity}>
                        <AlertTitle>{alertMessage.title}</AlertTitle>
                        {alertMessage.description}
                    </Alert>
                )}
                <FullScreenOverlayToolbar
                    title={title}
                    description={description}
                    onClose={onClose}
                    tourData={tourData}
                    leftButtons={leftButtons}
                    centerButtons={centerButtons}
                    rightButtons={rightButtons}
                />
                <div className="full-screen-overlay__content">{children}</div>
            </div>
        </DarkProvider>
    );
};

export default FullScreenOverlay;
